.p-datatable .p-datatable-header {
  background: navy !important;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.clr-gold {
  color: gold !important;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 2px 0;
  font-weight: 600;
  color: gold !important;
  background: navy !important;
  transition: box-shadow 0.15s;
}

.App-header {
  /* background-color: #282c34; */
  background-color: grey;
  min-height: 100vh;
}

.product_image{
  width: 150px;
  height: 175px;
  position: relative; 
  text-align: center;
  border: 3px dashed #ccc; 
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guZdik {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-width: 90% !important;
  max-width: 95% !important;
  height: 55px !important;
  border: 2.5px dashed rgb(6, 88, 194) !important;
  padding: 8px 16px 8px 8px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-box-flex: 0;
  flex-grow: 0;
}

.p-password .p-component {
  width: 100% !important;
}

.btnLabel {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  border-radius: 5px;
  background-color: dodgerblue;
  width: 200px;
  height: 50px;
  margin: 5px;
  margin-top: -5px;
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.5);
}

.listbox {
  background: navy;
  color: white;
  padding: 10px;
  padding-left: 2.5vw;
  text-align: left;

}

.listbox:hover {
  color: navy;
  background: white;
  font-weight: 700 !important;
}

.listboxs  {
  color: navy !important;
  background: gold !important;
  font-weight: 700 !important;
  padding: 10px;
  padding-left: 2.5vw;
  text-align: left;
}

.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem !important;
}